<template>
  <!-- 提示 -->
  <div class="custom-tips">
    <el-dialog title="提示" :visible.sync="dialogVisible" center width="40%" :show-close="false">

      <div class="tips-content">
        <div v-for="item in list" :key="item.index">
			第{{item.index+1}}行有问题，应以"{{item.type?'问':'答'}}"字开头，请仔细检查。
		</div>
      </div>

      <div slot="footer" class="dialog-footer">
        <custom-button confirmText="好的" type="confirm" @click.native="dialogVisible = false"></custom-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SaveTemplate',
  props:['list'],
  data() {
    return {
      value: '',
      input: '',
      searchVal: '',
      dialogVisible: false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    }
  }
}
</script>
<style lang="scss">
.custom-tips {

  .el-dialog {
    border-radius: .25rem;
  }

  .el-dialog__title {
    font-weight: bold;
    color: #000000;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: .45rem;
    color: #909399;
  }

  .tips-content {
    display: flex;
    flex-direction: column;
  }


  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>