import axios from "axios";
import { baseURL } from './config'
import store from '../store/index.js'

//1. 创建axios实例,配置基础路径
const api = axios.create({
	baseURL,
	timeout: 30000,//连接超时
});

// 定义请求拦截器:给所有请求都带上token
api.interceptors.request.use((req) => {
	let token = store.state.userInfo.token;//获取页面存储的token
	if (token) req.headers.token = token;
	return req;
}, (error) => {//返回错误的信息
	console.log("1=======>",error)
	return Promise.reject(error);
});

// 定义响应拦截器: 对服务器响应客户端的数据进行统一处理
api.interceptors.response.use(res => {
	console.log(res.data);
	return res.data
})

export default api;