<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    // this.$store.dispatch('init')
  }
}
</script>

<style lang="scss">
@import './assets/style/base.css';
</style>
