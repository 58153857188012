<template>
	<!-- 我的问题模板库 -->
	<div class="import-record-template">
		<el-dialog title="我的问题模板库" @open="getTemplateList()" @close="close" :visible.sync="dialogVisible" width="20rem"
			top="80px">

			<div class="template-content">
				<div class="template-controls">
					<div @click="$refs.saveTemplateRef.show()">
						<img src="../assets/images/record_icon14.png" alt="">
						<span>新增</span>
					</div>
					<div @click="setLawCaseTemplate">
						<img src="../assets/images/record_icon08.png" alt="">
						<span>保存</span>
					</div>
					<div @click="delCaseTemplate">
						<img src="../assets/images/record_icon09.png" alt="">
						<span>删除</span>
					</div>
					<div @click="dleAllContent()">
						<img src="../assets/images/record_icon15.png" alt="">
						<span>全部删除回答内容</span>
					</div>
					<div @click="dleCurrentContent()">
						<img src="../assets/images/record_icon16.png" alt="">
						<span>删除回答内容</span>
					</div>
				</div>

				<div class="template-inner">
					<div class="template-list">
						<el-table :data="tableData" height="100%" border highlight-current-row
							@current-change="handleCurrentChange">
							<el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
							<el-table-column prop="template_name" label="模板名称" width="150"
								align="center"></el-table-column>
							<el-table-column prop="classification_id" label="案件类别" align="center">
								<div slot-scope="scope">
									{{scope.row.classificationName}}
								</div>
							</el-table-column>
						</el-table>
					</div>

					<div class="template-qa">
						<div class="qa-choose">
							<div class="flex-row-center">
								<div class="mr-10">模板名称</div>
								<div>
									<el-input v-model="params2.template_name" placeholder="请输入"></el-input>
								</div>
							</div>
							<div>
								<div class="mr-10">案件类别</div>
								<div class="mr-10">
									<el-input :value="classificationName" placeholder="请输入"></el-input>
								</div>
								<div @click="$refs.chooseCaseCategoryRef.show()">选择</div>
							</div>
						</div>
						<div class="qa-set">
							<div class="flex-row-center">
								<div class="font-title mr-10">字体</div>
								<div>
									<el-select style="width: 1.625rem" v-model="params2.font" placeholder="请选择">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
								<div style="margin-left: .1875rem;">
									<el-select style="width: 1.625rem" v-model="params2.font_size" placeholder="请选择">
										<el-option v-for="item in options" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</div>
							</div>

							<div class="flex-row-center ml-20">
								<div class="font-title mr-10">问题</div>
								<div>
									<el-color-picker v-model="params2.type0_color"></el-color-picker>
								</div>
							</div>

							<div class="flex-row-center ml-20">
								<div class="font-title mr-10">回答</div>
								<div>
									<el-color-picker v-model="params2.type1_color"></el-color-picker>
								</div>
							</div>

							<div class="flex-row-center ml-20">
								<div class="font-title mr-10">背景</div>
								<div>
									<el-color-picker v-model="params2.bg_color"></el-color-picker>
								</div>
							</div>
						</div>

						<div class="qa-list" :style="{background:params2.bg_color}">
							<div class="qa-item" v-for="(item, index) in params2.question" :key="index">
								<div>{{ index + 1 }}</div>
								<div style="margin-left: 0.3125rem;">{{item.type==0?'问：':'答：'}}</div>
								<el-input
									:style="{ '--inputColor':item.type==0?params2.type0_color:params2.type1_color }"
									v-model="item.content" :ref="`content`+index"
									@keyup.enter.native="enterAddInput($event,item,index)"></el-input>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<custom-button type="cancel" @click.native="dialogVisible = false"
					style="margin-right: .625rem;"></custom-button>
				<custom-button confirmText="导入" type="confirm" @click.native="toLead()"></custom-button>
			</div>
		</el-dialog>

		<!-- 选择案件类别 -->
		<choose-case-category ref="chooseCaseCategoryRef" @getItem="getCase"></choose-case-category>
		<!-- 保存/新增到我的模板 -->
		<save-template @getType="getType" ref="saveTemplateRef"></save-template>
	</div>
</template>

<script>
	import ChooseCaseCategory from "./ChooseCaseCategory.vue";
	import SaveTemplate from "../components/SaveTemplate.vue";
	export default {
		name: 'ImportRecordTemplate',
		props: ['LawCaseTypeList'],
		components: {
			'choose-case-category': ChooseCaseCategory,
			'save-template': SaveTemplate,
		},
		data() {
			return {
				selectTemplateId: '',
				options: {},
				color1: '',
				value: '',
				dialogVisible: false,
				tableData: [],
				// 对话问答笔录信息
				params2: {
					num_id: '', //笔录id
					font: '', //字体
					font_size: '16', //字体大小
					//question.type0问  1答
					question: [{
						type: 0,
						content: '',
					}],
					classification_id: '',
					type0_color: '#000000', //问颜色
					type2_color: '#000000', //答颜色
					bg_color: '#F5F9FE', //背景色
				},
				classificationName: "", //案件类别
			}
		},
		created() {
			this.getTemplateList()
		},
		methods: {
			show() {
				this.dialogVisible = true;
			},
			close() {
				this.reset()
				this.dialogVisible = false;
			},
			reset() {
				this.params2 = {
					num_id: '', //笔录id
					font: '', //字体
					font_size: '16', //字体大小
					//question.type0问  1答
					question: [{
						type: 0,
						content: '',
					}],
					classification_id: '', //案件类型id
					type0_color: '#000000', //问颜色
					type2_color: '#000000', //答颜色
					bg_color: '#F5F9FE', //背景色
				}
			},
			// 获取模版库列表
			getTemplateList() {
				this.$api.post('/police/case_data/case_template_list', {}).then(res => {
					// console.log("模版库列表", res)
					if (res.code == 1) {
						this.tableData = res.data.list
						this.tableData.forEach(item => {
							this.LawCaseTypeList.forEach(item2 => {
								if (item.classification_id == item2.id) {
									item.classificationName = item2.name
								}
							})
						})
					} else {
						this.$use.msg(res.msg, 'error');
					}
				}).catch(err => {
					this.$use.msg(err.msg, 'error');
				})
			},
			// 点击选中模板
			handleCurrentChange(e) {
				if (!e) {
					return
				}
				this.selectTemplateId = e.id
				this.tableData.forEach(item => {
					if (item.id == e.id) {
						this.params2.num_id = e.id //笔录id
						this.params2.font = item.font //字体
						this.params2.font_size = item.font_size //字体大小
						this.params2.classification_id = item.classification_id
						this.params2.type0_color = item.type0_color //问颜色
						this.params2.type1_color = item.type1_color //答颜色
						this.params2.bg_color = item.bg_color //背景色
						this.classificationName = item.classificationName
						this.params2.template_name = item.template_name

						if (item.question && item.question.length > 0) {
							this.params2.question = item.question
						} else {
							this.params2.question = [{
								type: 0,
								content: '',
							}]
						}
					}
				})
			},
			// 点击选中案件类型
			getCase(e) {
				this.params2.classification_id = e.id
				this.classificationName = e.name
			},
			// 按下回车键  添加输入框
			enterAddInput(e, row, index) {
				// 如果是在最后一行点击的回车键
				if (this.params2.question.length - 1 == index) {
					let refTitle = 'content' + index
					let refTitle2 = 'content' + (index + 1)

					this.params2.question.push({
						type: row.type == 0 ? 1 : 0,
						content: '',
					})

					this.$refs[refTitle][0].blur();
					setTimeout(() => {
						this.$refs[refTitle2][0].focus();
					}, 10)
				}
			},
			// 点击新增到模版库参数
			getType(name, lawCaseType) {
				let par = {
					template_name: name, //模版名称
					classification_id: lawCaseType.id, //案件类型id
					font_size: '16', //字号
					font: '', //字体
					type0_color: "#000", //问颜色
					type1_color: "#000", //答颜色
					bg_color: '#F5F9FE', //背景色
					question: [],
				}
				this.addLawCaseTemplate(par)
			},
			// 添加到模版库 
			addLawCaseTemplate(par) {
				this.$api.post('/police/case_data/case_template_add', par).then(res => {
						if (res.code == 1) {
							this.getTemplateList()
							this.reset()
						} else {
							this.$use.msg(res.msg || '添加失败', 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '添加失败', 'error');
					})
			},
			// 编辑/保存模版  字体等
			setLawCaseTemplate() {
				if (!this.selectTemplateId) {
					this.$use.msg("请先选择模版", 'warning');
					return
				}

				let par = {
					id: this.selectTemplateId,
					template_name: this.params2.template_name, //模版名称
					classification_id: this.params2.classification_id, //案件类型id
					font_size: this.params2.font_size, //字号
					font: this.params2.font, //字体
					type0_color: this.params2.type0_color, //问颜色
					type1_color: this.params2.type1_color, //答颜色
					bg_color: this.params2.bg_color, //背景色
					question: this.params2.question
				}

				this.$api.post('/police/case_data/case_template_edit', par).then(res => {
					if (res.code == 1) {
						this.reset()
						this.getTemplateList()
					} else {
						this.$use.msg(res.msg, 'error');
					}
				}).catch(err => {
					this.$use.msg(err.msg || '保存失败', 'error');
				})
			},
			// 删除当前模版
			delCaseTemplate() {
				if (!this.selectTemplateId) {
					this.$use.msg("请先选择模版", 'warning');
					return
				}
				this.$api.post('/police/case_data/case_template_del', {
					id: this.selectTemplateId
				}).then(res => {
					console.log("删除当前模版", res)
					if (res.code == 1) {
						this.reset()
						this.getTemplateList()
						this.$use.msg('删除成功', 'success');
					} else {
						this.$use.msg(res.msg, 'error');
					}
				}).catch(err => {
					this.$use.msg(err.msg || '保存失败', 'error');
				})
			},
			// 删除回答内容  当前选中模版的内容
			dleCurrentContent(type = 'one', allItem) {
				let par = {}
				if (type == 'one') {
					if (!this.selectTemplateId) {
						this.$use.msg("请先选择模版", 'warning');
						return
					}

					if (this.params2.question && this.params2.question.length > 0) {
						this.params2.question.forEach(item => {
							if (item.type == 1) {
								item.content = ""
							}
						})
					}
					par = {
						id: this.selectTemplateId,
						template_name: this.params2.template_name, //模版名称
						classification_id: this.params2.classification_id, //案件类型id
						font_size: this.params2.font_size, //字号
						font: this.params2.font, //字体
						type0_color: this.params2.type0_color, //问颜色
						type1_color: this.params2.type1_color, //答颜色
						bg_color: this.params2.bg_color, //背景色
						question: this.params2.question
					}
				} else if (type == 'all') {
					par = allItem
				}

				this.$api.post('/police/case_data/case_template_edit', par).then(res => {
					if (res.code == 1) {
						this.$use.msg('删除成功', 'success');
						this.reset()
						this.getTemplateList()
					} else {
						this.$use.msg(res.msg, 'error');
					}
				}).catch(err => {
					this.$use.msg(err.msg || '删除失败', 'error');
				})

			},
			// 全部删除回答内容 删除所有模版回答内容
			dleAllContent() {
				this.$api.post('/police/case_data/case_template_all_clear').then(res => {
					if (res.code == 1) {
						this.$use.msg('删除成功', 'success');
					}
				}).catch(err => {
					this.$use.msg(err.msg || '删除失败', 'error');
				})
			},
			// 点击导入按钮
			toLead() {
				if (!this.selectTemplateId) {
					this.$use.msg("请先选择模版", 'warning');
					return
				}

				let par = {
					id: this.selectTemplateId,
					template_name: this.params2.template_name, //模版名称
					classification_id: this.params2.classification_id, //案件类型id
					font_size: this.params2.font_size, //字号
					font: this.params2.font, //字体
					type0_color: this.params2.type0_color, //问颜色
					type1_color: this.params2.type1_color, //答颜色
					bg_color: this.params2.bg_color, //背景色
					question: this.params2.question
				}
				this.dialogVisible = false
				this.$emit('getToLead', par)

			}
		}
	}
</script>
<style lang="scss">
	.import-record-template {

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.el-dialog__headerbtn .el-dialog__close {
			font-size: .45rem;
			color: #909399;
		}

		.template-content {
			display: flex;
			flex-direction: column;
			max-height: 60vh;
			min-height: 600px;

			.el-table__body tr.current-row>td {
				background: linear-gradient(180deg, #53B6F4 0%, #1F8CFE 100%) !important;
				color: #fff;
			}

			.el-table th {
				font-weight: bold;
				font-size: .2375rem;
				color: #333333;
				background-color: #CCE5FF;
			}

			.template-inner {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				margin-top: .375rem;
				flex: 1;

				.template-list {
					display: flex;
					flex-direction: column;
					width: 6.5rem;
					height: 100%;
				}

				.template-qa {
					display: flex;
					flex-direction: column;
					width: 12.25rem;
					height: 100%;
					margin-left: .625rem;

					.qa-choose {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 20px;
						color: #333333;

						>div {
							display: flex;
							align-items: center;

							&:last-child {
								>div {
									&:last-child {
										display: flex;
										align-items: center;
										justify-content: center;
										width: 80px;
										height: 40px;
										background: linear-gradient(90deg, #53B6F4 0%, #1F8CFE 100%);
										border-radius: .1rem;
										font-weight: bold;
										font-size: 16px;
										color: #FFFFFF;
										cursor: pointer;
									}
								}
							}
						}
					}

					.qa-set {
						display: flex;
						align-items: flex-start;
						margin: 0 0 .25rem;
						height: 50px;
					}

					.qa-list {
						background: #F5F9FE;
						border-radius: .1875rem;
						overflow-y: auto;
						height: 380px;
						width: 100%;
						background-color: #ff5500;

						.qa-item {
							display: flex;
							align-items: center;
							height: .75rem;
							border-bottom: .0125rem solid #D1E5FF;

							.el-input__inner {
								background-color: rgba(0, 0, 0, 0);
								padding: 0;
								color: var(--inputColor); //使用css变量 注意变量前需要加 --
							}

							>div {
								font-size: .2rem;
								color: #333333;

								&:first-child {
									display: flex;
									align-items: center;
									justify-content: center;
									width: .75rem;
									height: .75rem;
									border-right: .0125rem solid #D1E5FF;
								}

								&:last-child {
									flex: 1;
									padding-left: .25rem;
								}
							}
						}
					}
				}
			}

			.template-controls {
				display: flex;
				align-items: flex-start;

				>div {
					display: flex;
					flex-direction: column;
					align-items: center;
					cursor: pointer;
					margin: 0 .125rem;
					min-width: 1rem;
					text-align: center;

					>img {
						width: .625rem;
						height: .625rem;
					}

					>span {
						margin-top: .125rem;
						font-size: 14px;
						color: #333333;
					}
				}
			}
		}


		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>