<template>
	<!-- 签名版 -->
	<div class="signature-edition">
		<el-dialog title="签名版" @close="close" :visible.sync="dialogVisible" width="50%">

			<div class="signature-edition-content">
				<vue-esign ref="esign" :width="800" :height="300" :isCrop="isCrop" :lineWidth="lineWidth"
					:lineColor="lineColor" />
			</div>

			<div slot="footer" class="dialog-footer">
				<custom-button confirmText="取消" type="cancel" @click.native="dialogVisible = false"></custom-button>
				<custom-button confirmText="重新签名" type="confirm" @click.native="handleReset"
					style="margin:0 .25rem"></custom-button>
				<custom-button confirmText="确定" type="confirm" @click.native="handleGenerate"></custom-button>
			</div>

		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'SignatureEdition',
		data() {
			return {
				dialogVisible: false,
				lineWidth: 6,
				lineColor: '#000000',
				bgColor: '#F8F8F8',
				resultImg: '',
				isCrop: false,
				imgSrc:null,
			}
		},
		methods: {
			show() {
				this.dialogVisible = true;
			},
			close() {
				this.imgSrc = null
				this.$refs['esign'].reset() //清空画布
				console.log("关闭回调")
				this.dialogVisible = false;
			},
			handleReset() {
				this.$refs['esign'].reset() //清空画布
			},
			handleGenerate() {
				this.$refs.esign.generate().then(res => {
					this.imgSrc = res;
					// console.log("Res", res)
					this.base64UpLoad(res)
				}).catch(err => {
					this.$message({
						message: err + ' 未签名！',
						type: 'warning'
					})
					console.log('画布没有签字时会执行这里 Not Signned')
				})
			},
			base64UpLoad(baseUrl) {
				this.$api.post('/api/common/base64Upload', {
						base64: baseUrl
					}).then(res => {
						if (res.code == 1) {
							let par = {
								img:res.data,
								type:1
							}
							this.$emit('confirmSignature',par)
							this.dialogVisible = false
						}else{
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(err.msg || '上传失败', 'error');
					})
			},
		}
	}
</script>
<style lang="scss">
	.signature-edition {

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.signature-edition-content {
			background-color: #F8F8F8;

			>div {
				&:first-child {
					color: #000000;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: .25rem;

					>span {
						&:first-child {
							font-weight: bold;
							font-size: 16px;
							color: #000000;
						}

						&:last-child {
							font-size: 16px;
							color: #999999;
						}
					}
				}

				&:not(:first-child) {
					font-weight: bold;
					font-size: 14px;
					color: #000000;
					line-height: 2;
				}
			}
		}


		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>