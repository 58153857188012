import Vue from 'vue'
import store from '../store/index.js'

import VueRouter from 'vue-router'
import home from '../views/home.vue'
import login from '../views/login.vue'
import recordAdd from '../views/record-add.vue'
import lookEvidence from '../views/look-evidence.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/recordAdd',
    name: 'recordAdd',
    component: recordAdd
  },
  {
    path: '/lookEvidence',
    name: 'lookEvidence',
    component: lookEvidence
  }
]

const router = new VueRouter({
  routes
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (!store.state.userInfo.token) {
    if (to.path != '/login') {
      next('/login')
    } else {
      next();
    }
  } else {
    next();
  }
});


// export default new Router({
//   mode: 'hash', // 去掉url中的#  history
//   scrollBehavior: () => ({
//     y: 0
//   }),
//   routes: routes
// })


export default router
