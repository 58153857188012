<template>
  <!-- 查找/替换 -->
  <div class="find-replace">
    <el-dialog title="查找/替换" :visible.sync="dialogVisible" @open="changeOpen" center width="40%" :show-close="false">

      <div class="find-replace-content">
        <div>
          <div>查找内容</div>
          <div>
            <el-input v-model="keyWord" placeholder="请输入"></el-input>
          </div>
        </div>
        <div>
          <div>替换为</div>
          <div>
            <el-input v-model="keyWord2" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <custom-button type="cancel" @click.native="dialogVisible = false"></custom-button>
        <custom-button type="confirm" @click.native="confirmUpWord"
          style="margin-left: .975rem;"></custom-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'FindReplace',
  data() {
    return {
      keyWord: '',
      keyWord2: '',
      searchVal: '',
      dialogVisible: false
    }
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
	// 监听对话框打开事件  将搜索条件重置
	changeOpen(){
		this.keyWord = ""
		this.keyWord2 = ""
	},
	// 点击确认  替换内容
	confirmUpWord(){
		if(this.keyWord.trim() == ''){
			this.$use.msg('请输入查找条件', 'warning');
			return
		}else if(this.keyWord2.trim() == ''){
			this.$use.msg('请输入查找条件', 'warning');
			return
		}
		
		this.$emit('upContent',this.keyWord,this.keyWord2)
		this.dialogVisible = false;
	}
  }
}
</script>
<style lang="scss">
.find-replace {

  .el-dialog {
    border-radius: .25rem;
  }

  .el-dialog__title {
    font-weight: bold;
    color: #000000;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: .45rem;
    color: #909399;
  }

  .find-replace-content {
    display: flex;
    flex-direction: column;

    >div {
      display: flex;
      align-items: center;
      margin-bottom: .375rem;

      >div {
        &:first-child {
          font-weight: bold;
          font-size: .275rem;
          color: #333333;
          width: 1.5rem;
        }

        &:last-child {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>