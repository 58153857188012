<template>
  <!-- 选择被询(讯)问人 -->
  <div class="select-interrogator">
    <el-dialog title="选择被询(讯)问人" :visible.sync="showPopup" width="60%">

      <div class="case-category-content">
        <div class="content-search">
          <div>
            <el-input v-model="searchVal" placeholder="请输入" @input="getList"></el-input>
          </div>
          <div>请在输入框输入被询(讯)问人手机号或身份证号，可以快速检索。</div>
        </div>

        <div class="content-table">
          <el-table :data="list" border height="5rem" highlight-current-row @current-change="handleCurrentChange">
            <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
            <el-table-column prop="real_name" label="姓名" width="100" align="center"></el-table-column>
            <el-table-column prop="mobile" label="手机号" align="center" width="130"></el-table-column>
            <el-table-column prop="card_num" label="身份证号" width="180" align="center"></el-table-column>
            <el-table-column prop="create_time_text" label="认证时间" align="center"></el-table-column>
          </el-table>
        </div>

        <div class="table-tips">注：单击列表选择被询(讯)问人姓名</div>

      </div>

      <div slot="footer" class="dialog-footer">
        <custom-button type="cancel" @click.native="cancel"></custom-button>
        <custom-button type="confirm" @click.native="confirm" style="margin-left: .975rem;"></custom-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SelectInterrogator',
  data() {
    return {
      list: [],
      selectedItem: null,
      searchVal: '',
      showPopup: false,
    }
  },
  methods: {
    async getList() {
      const res = await this.$api.post('/police/case_data/case_ask_people_list', {
        search_str: this.searchVal
      })
      if (res.code == 1) {
        this.list = res.data.list;
      } else {
        this.$use.msg(res.msg, 'error');
      }
    },
    show() {
      this.getList();
      this.showPopup = true;
    },
    close() {
      this.showPopup = false;
      this.list = [];
      this.selectedItem = null;
    },
    cancel() {
      this.close();
    },
    confirm() {
      if (!this.selectedItem) return this.$use.msg('选择被询(讯)问人', 'error')
      this.$emit('getItem', this.selectedItem)
      this.close();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.selectedItem = val;
    }
  }
}
</script>
<style lang="scss">
.select-interrogator {
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 20px;
    color: #909399;
  }

  .el-dialog {
    border-radius: .25rem;
  }

  .el-dialog__title {
    font-weight: bold;
    color: #000000;
  }

  .case-category-content {

    .el-table__body tr.current-row>td {
      background: linear-gradient(180deg, #53B6F4 0%, #1F8CFE 100%) !important;
      color: #fff;
    }

    .el-table th {
      font-weight: bold;
      font-size: .2375rem;
      color: #333333;
      background-color: #CCE5FF;
    }

    .content-table {
      margin-top: .375rem;
    }

    .table-tips {
      font-size: .225rem;
      color: #218CFF;
      margin-top: .25rem;
    }

    .content-search {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >div {
        &:first-child {
          width: 3.1875rem;
        }

        &:last-child {
          flex: 1;
          margin-left: .25rem;
          font-size: .225rem;
          color: #218CFF;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>