import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/flexible.js'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Request from './utils/request';
Vue.prototype.$api = Request;

import use from './utils/use'
Vue.prototype.$use = use;

import {baseURL} from './utils/config'
Vue.prototype.$baseURL = baseURL;

import vueEsign from 'vue-esign'
Vue.use(vueEsign)

import customButton from './components/CustomButton.vue';
Vue.component('custom-button', customButton);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
