<template>
  <div class="custom-button">
    <div class="cancel" v-if="type == 'cancel'">取消</div>
    <div class="confirm" v-if="type == 'confirm'">{{ confirmText }}</div>
  </div>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    type: {
      type: String,
      default: 'confirm'
    },
    confirmText: {
      type: String,
      default: '确定'
    }
  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
<style lang="scss">
.custom-button {

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 160px;
    height: 50px;
    font-size: 18px;
    border-radius: 12.4875rem;
  }

  .cancel {
    color: #999999;
    background: rgba(0, 0, 0, 0.05);
  }

  .confirm {
    color: #FFFFFF;
    background: linear-gradient(222deg, #53B6F4 0%, #1F8CFE 100%);
  }

}
</style>