import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: JSON.parse(sessionStorage.getItem("ycqz:user") || '{}')
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload;
      sessionStorage.setItem("ycqz:user", JSON.stringify(payload));
    }
  },
  actions: {
    async login({
      state,
      commit
    }, data) {
      commit('setUserInfo', data);
    },

    async logout({
      state
    }) {
      state.userInfo = {};
      sessionStorage.removeItem('ycqz:user')
    },
  },
})
