<template>
	<!-- 选择被询(讯)问人 -->
	<div class="select-interrogator">
		<el-dialog title="笔录记录" @open="getList" :visible.sync="showPopup" width="60%">
			<div class="case-category-content">
				<div class="content-table">
					<el-table :data="dataList.list" border height="5rem" highlight-current-row
						@current-change="handleCurrentChange">
						<el-table-column type="index" label="序号" width="120" align="center"></el-table-column>
						<el-table-column label="姓名" width="100" align="center">
							<div slot-scope="scope">
								{{scope.row.content.name}}
							</div>
						</el-table-column>
						<el-table-column label="人员类型" align="center" width="130">
							<div slot-scope="scope">
								{{scope.row.content.personnel_type}}
							</div>
						</el-table-column>
						<el-table-column label="身份证号" width="180" align="center">
							<div slot-scope="scope">
								{{scope.row.content.card_num}}
							</div>
						</el-table-column>
						<el-table-column prop="create_time_text" label="保存时间" align="center">
							<div slot-scope="scope">
								{{scope.row.createtime_text}}
							</div>
						</el-table-column>
					</el-table>
				</div>

				<div class="table-tips">注：单击列表选择笔录记录</div>
				<div style="width: 100%;text-align: center;">
					<el-pagination @current-change="currentChange" background layout="prev, pager, next" :total="dataList.total_count">
					</el-pagination>
				</div>
				
			</div>

			<div slot="footer" class="dialog-footer">
				<custom-button type="cancel" @click.native="cancel"></custom-button>
				<custom-button type="confirm" @click.native="confirm" style="margin-left: .975rem;"></custom-button>
			</div>

		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'SelectInterrogator',
		props: ['num_id'],
		data() {
			return {
				dataList: {},
				selectedItem: null,
				searchVal: '',
				showPopup: false,
				params:{
					limit: 10, //条数
					page: 1, //页码
				}
			}
		},
		methods: {
			getList() {
				this.$api.post('/police/case_data/case_num_log', this.params).then(res => {
						if (res.code == 1) {
							// console.log("笔录记录", res)
							this.dataList = res.data
						}else{
							this.$use.msg(res.msg, 'error');
						}
					})
					.catch(err => {
						this.$use.msg(res.msg, 'error');
					})
			},
			show() {
				this.showPopup = true;
			},
			open(){
				this.dataList = {};
				this.selectedItem = null;
				this.params.page = 1
				this.getList()
			},
			close() {
				this.showPopup = false;
				this.dataList = {};
				this.selectedItem = null;
			},
			cancel() {
				this.close();
			},
			confirm() {
				if (!this.selectedItem) return this.$use.msg('选择被询(讯)问人', 'error')
				this.$emit('getItem', this.selectedItem)
				this.close();
			},
			handleCurrentChange(val) {
				// console.log("笔录记录", val);
				this.selectedItem = val;
			},
			// 页面改变时触发
			currentChange(e){
				console.log('页面改变时触发',e)
				this.params.page = e
				this.getList()
			}
		}
	}
</script>
<style lang="scss">
	.select-interrogator {
		.el-dialog__headerbtn .el-dialog__close {
			font-size: 20px;
			color: #909399;
		}

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.case-category-content {

			.el-table__body tr.current-row>td {
				background: linear-gradient(180deg, #53B6F4 0%, #1F8CFE 100%) !important;
				color: #fff;
			}

			.el-table th {
				font-weight: bold;
				font-size: .2375rem;
				color: #333333;
				background-color: #CCE5FF;
			}

			.content-table {
				margin-top: .375rem;
			}

			.table-tips {
				font-size: .225rem;
				color: #218CFF;
				margin-top: .25rem;
			}

			.content-search {
				display: flex;
				align-items: center;
				justify-content: space-between;

				>div {
					&:first-child {
						width: 3.1875rem;
					}

					&:last-child {
						flex: 1;
						margin-left: .25rem;
						font-size: .225rem;
						color: #218CFF;
					}
				}
			}
		}

		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>