<template>
  <div class="login">
    <div class="login-left">
      <img src="../assets/images/login_bg.png" alt="">
    </div>
    <div class="login-right">
      <div class="login-content">
        <div class="login-title">
          <img src="../assets/images/logo.png" alt="">
          <span>昆明市公安局远程询问取证系统</span>
        </div>
        <div class="login-input">
          <div>
            <img src="../assets/images/login_input_icon01.png" alt="">
          </div>
          <input v-model="account" type="text" placeholder="请输入账号">
        </div>
        <div class="login-input">
          <div>
            <img src="../assets/images/login_input_icon02.png" alt="">
          </div>
          <input v-model="password" type="password" placeholder="请输入密码">
        </div>
        <div class="login-captcha">
          <div>
            <input v-model="code" type="text" placeholder="输入验证码">
          </div>
          <div @click="updateVerify">
            <img :src="`${$baseURL}/police/police/verify?key=${key}`" alt="">
          </div>
          <div @click="updateVerify">看不清，换一张</div>
        </div>
        <div class="login-savepwd">
          <el-checkbox v-model="isSavePwd">记住密码</el-checkbox>
        </div>
        <div class="login-login" @click="login">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: '',
      isSavePwd: false,
      account: '',
      password: '',
      key: '',
      code: ''
    }
  },
  created() {
    this.updateVerify();
    this.checkSavePwdState();
  },
  methods: {
    checkSavePwdState() {
      let bool = localStorage.getItem('ycqzAccount');
      this.isSavePwd = bool ? true : false;
    },

    async login() {
      if (!this.account) return this.$use.msg('请输入账号', 'error');
      if (!this.password) return this.$use.msg('请输入密码', 'error');
      if (!this.code) return this.$use.msg('请输入验证码', 'error');
      const res = await this.$api.post('/police/police/login', {
        account: this.account,
        password: this.password,
        key: this.key,
        code: this.code
      })
	  console.log("login",res)
      this.$use.msg(res.msg, res.code == 1 ? 'success' : 'error');
      if (res.code == 1) {
        if (this.isSavePwd) {
          localStorage.setItem('ycqzAccount', this.account);
          localStorage.setItem('ycqzPassword', this.password);
        } else {
          localStorage.removeItem('ycqzAccount');
          localStorage.removeItem('ycqzPassword');
        }
        this.$store.dispatch('login', res.data.userinfo)
        this.$router.push('/')
      }
    },

    // 更新图形验证码
    updateVerify() {
      let code = '';
      for (let i = 0; i < 6; i++) {
        code += parseInt(Math.random() * 10)
      }
      this.key = code;
    }
  }
}
</script>
<style lang="scss">
.login {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;

  .login-left {
    width: 14.2rem;
    height: 100%;
    overflow: hidden;

    >img {
      width: 100%;
      height: 100%;
    }
  }

  .login-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .login-content {
      width: 6.5rem;
      background: #FFFFFF;
      box-shadow: 0px .2125rem .45rem .0125rem rgba(23, 129, 222, 0.05);
      border-radius: .3125rem;
      box-sizing: content-box;
      padding: .375rem;
      border-radius: .2rem;

      .login-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .625rem;

        >img {
          width: 1.3625rem;
          height: 1.3625rem;
        }

        >span {
          font-weight: bold;
          font-size: .55rem;
          color: #02112B;
          margin-left: .125rem;
        }
      }

      .login-input {
        display: flex;
        align-items: center;
        width: 6.5rem;
        height: .95rem;
        background: #F5F9FE;
        margin-bottom: .5rem;
        padding: .1875rem .25rem .1875rem 0;
        border-radius: .125rem;

        >div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: .95rem;
          height: 100%;
          border-right: .0125rem solid #EEEEEE;

          >img {
            width: .425rem;
            height: .425rem;
          }

        }

        input {
          margin-left: .25rem;
          font-size: .275rem;
          width: 100%;
          background: #F5F9FE;
          height: 100%;
        }

      }

      .login-captcha {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .375rem;

        >div {
          &:first-child {
            width: 2.125rem;
            height: .8rem;
            background: #F8F8F8;
            border-radius: .125rem;
            padding: 0 .25rem;

            input {
              width: 100%;
              height: 100%;
              font-size: .275rem;
            }

          }

          &:nth-child(2) {
            width: 2.025rem;
            height: .925rem;
            cursor: pointer;

            >img {
              width: 100%;
              height: 100%;
            }
          }

          &:last-child {
            font-size: .25rem;
            color: #999999;
            border-bottom: .0125rem solid #218CFF;
            padding-bottom: .125rem;
            cursor: pointer;
          }
        }
      }

      .login-savepwd {
        align-self: start;
      }

      .login-login {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 6.5rem;
        height: .95rem;
        background: linear-gradient(90deg, #53B6F4 0%, #1F8CFE 100%);
        border-radius: .15rem;
        font-weight: bold;
        font-size: .325rem;
        color: #FFFFFF;
        margin-top: .75rem;
        cursor: pointer;
      }
    }
  }

}
</style>