<template>
    <div class="nav-bar">
        <div class="nav-content">
            <div @click="$router.replace('/')">
                <img src="../assets/images/logo.png" alt="">
                <span>昆明市公安局远程询问取证系统</span>
            </div>
            <div>
                <el-dropdown @command="command">
                    <div class="nav-user">
                        <img :src="`${$baseURL}${$store.state.userInfo.avatar}`" alt="">
                        <span>你好，{{ $store.state.userInfo.nickname || $store.state.userInfo.username }}</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="editPwd">修改密码</el-dropdown-item>
                        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>
        </div>
        <div class="nav-placeholder"></div>

        <edit-pwd ref="editPwdRef"></edit-pwd>
    </div>
</template>

<script>
import editPwd from "../components/editPwd.vue";
export default {
    name: 'navBar',
    components: {
        'edit-pwd': editPwd
    },
    data() {
        return {

        }
    },
    methods: {
        command(e) {
            switch (e) {
                case 'editPwd':
                    this.$refs.editPwdRef.show();
                    break;
                case 'logout':
                    this.$store.dispatch('logout');
                    this.$router.replace('/login');
                    break;
            }
        }
    }
}
</script>
<style lang="scss">
.nav-bar {

    .nav-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 1.3125rem;
        background: #FFFFFF;
        padding: 0 .5rem;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;

        >div {
            display: flex;
            align-items: center;
            cursor: pointer;

            &:first-child {

                >img {
                    width: .7rem;
                    height: .7rem;
                }

                >span {
                    font-weight: bold;
                    font-size: .375rem;
                    color: #000000;
                    margin-left: .25rem;
                }
            }

            &:last-child {
                .nav-user {
                    display: flex;
                    align-items: center;

                    >img {
                        width: .6rem;
                        height: .6rem;
                        border-radius: 50%;
                    }

                    >span {
                        font-weight: bold;
                        font-size: .25rem;
                        color: #333333;
                        margin: .125rem;
                    }
                }
            }
        }
    }

    .nav-placeholder {
        width: 100vw;
        height: 1.3125rem;
    }
}
</style>