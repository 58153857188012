<template>
  <!-- 修改密码 -->
  <div class="edit-pwd">
    <el-dialog title="修改密码" :visible.sync="dialogVisible" center width="40%" :show-close="false">

      <div class="edit-pwd-content">
        <div>
          <div>原密码</div>
          <div>
            <el-input v-model="oldpassword" type="password" placeholder="请输入原密码"></el-input>
          </div>
        </div>
        <div>
          <div>新密码</div>
          <div>
            <el-input v-model="newpassword" type="password" placeholder="请输入新密码"></el-input>
          </div>
        </div>
        <div>
          <div>确认密码</div>
          <div>
            <el-input v-model="confirmnewpassword" type="password" placeholder="请确认新密码"></el-input>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <custom-button type="cancel" @click.native="dialogVisible = false"></custom-button>
        <custom-button type="confirm" @click.native="editPwd" style="margin-left: .975rem;"></custom-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'FindReplace',
  data() {
    return {
      dialogVisible: false,

      oldpassword: '',
      newpassword: '',
      confirmnewpassword: ''
    }
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    async editPwd() {
      if (!this.oldpassword) return this.$use.msg('请输入原密码', 'error');
      if (!this.newpassword) return this.$use.msg('请输入新密码', 'error');
      if (!this.confirmnewpassword) return this.$use.msg('请再次输入新密码', 'error');
      const res = await this.$api.post('/police/police/updatepwd', {
        oldpassword: this.oldpassword,
        newpassword: this.newpassword
      })
      this.$use.msg(res.msg, res.code == 1 ? 'success' : 'error');
      if (res.code == 1) {
        this.close()
        this.$store.dispatch('logout');
        this.$router.replace('/login');
      }
    }
  }
}
</script>
<style lang="scss">
.edit-pwd {

  .el-dialog {
    border-radius: .25rem;
  }

  .el-dialog__title {
    font-weight: bold;
    color: #000000;
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: .45rem;
    color: #909399;
  }

  .edit-pwd-content {
    display: flex;
    flex-direction: column;

    >div {
      display: flex;
      align-items: center;
      margin-bottom: .375rem;

      >div {
        &:first-child {
          font-weight: bold;
          font-size: .275rem;
          color: #333333;
          width: 1.5rem;
        }

        &:last-child {
          display: flex;
          align-items: center;
          flex: 1;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>