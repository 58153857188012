<template>
	<!-- 保存笔录信息 -->
	<div class="save-record-success">
		<el-dialog :title="signatureType==1?'保存笔录信息':signatureType==2?'笔录签名':'民警签名'" @open="open" :visible.sync="dialogVisible" center width="40%" :show-close="false">
			<div class="save-content">
				<div>
					<span>保存成功！请认真核对以下信息</span>
					<span>当前时间：{{$use.dateTimeInfo(new Date().getTime())}}</span>
				</div>
				<div>询(讯)问次数：{{params.num||''}}</div>
				<div>开始时间：{{params.begin_time||''}}</div>
				<div style="display: flex;align-items: center;">
					<span>结束时间：{{params.end_time||''}}</span>
					<div @click="amendTime" class="amend_time_btn">修正结束时间</div>
				</div>
				<div>案件编号：{{params.case_classification||''}}</div>
				<div>被询(讯)问人姓名：{{params.name||''}}</div>
				<div>被询(讯)问人身份证号码：{{params.card_num||''}}</div>
				<div v-if="police_ids&&police_ids.length">
					<div v-for="(item,index) in police_ids" :key="item.id">
						<div>询(讯)问人姓名{{index+1}}：{{item.nickname||''}}</div>
						<div>询(讯)问人单位{{index+1}}：{{item.work_address||''}}</div>
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<custom-button v-if="signatureType!=1" confirmText="确定并发起签名" type="confirm" @click.native="dialogClickConfirm(signatureType)"></custom-button>
				<custom-button v-else confirmText="确定" type="confirm" @click.native="dialogClickConfirm(1)"></custom-button>
			</div>

		</el-dialog>
	</div>
</template>

<script>
	/**
	 * @signatureType  点击保存 1保存后的预览、2签名、3民警签名  用于模态框展示信息
	*/
	export default {
		name: 'SaveRecordSuccess',
		props: ['params', 'police_ids','signatureType'],
		data() {
			return {
				dialogVisible: false
			}
		},
		methods: {
			open() {
				// console.log("保存笔录回显数据", this.params)
			},
			show() {
				this.dialogVisible = true;
			},
			close() {
				this.dialogVisible = false;
			},
			// 点击保存笔录
			dialogClickConfirm(type) {
				this.$emit('dialogClickConfirm',type)
				this.close()
			},
			// 点击修正结束时间/
			amendTime(){
				this.$emit('amendTime')
			}
		}
	}
</script>
<style lang="scss">
	.save-record-success {

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.el-dialog__headerbtn .el-dialog__close {
			font-size: .45rem;
			color: #909399;
		}

		.save-content {
			>div {
				&:first-child {
					color: #000000;
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: .25rem;

					>span {
						&:first-child {
							font-weight: bold;
							font-size: 16px;
							color: #000000;
						}

						&:last-child {
							font-size: 16px;
							color: #999999;
						}
					}
				}

				&:not(:first-child) {
					font-weight: bold;
					font-size: 14px;
					color: #000000;
					line-height: 2;
				}
			}
			.amend_time_btn{
				width: 100px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient( 222deg, #53B6F4 0%, #1F8CFE 100%);
				border-radius: 10px 10px 10px 10px;
				font-weight: bold;
				font-size: 12px;
				color: #FFFFFF;
				margin-left: 10px;
				cursor: pointer;
			}
		}


		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>