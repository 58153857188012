<template>
	<!-- 保存到我的模板 -->
	<div class="save-template">
		<el-dialog title="问题模板维护" :visible.sync="dialogVisible" center width="520px" :show-close="false">

			<div class="save-template-content">
				<div>
					<div>模板名称</div>
					<div class="flex-1">
						<el-input v-model="input" placeholder="请输入模板名称"></el-input>
					</div>
				</div>
				<div>
					<div>案件类别</div>
					<div class="flex-1">
						<el-input :value="lawCaseType.name" placeholder="请选择案件类别"></el-input>
						<div class="choose-category" @click="$refs.chooseCaseCategoryRef.show()">选择</div>
					</div>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<custom-button type="cancel" @click.native="close"></custom-button>
				<custom-button type="confirm" @click.native="clickConfirm()"
					style="margin-left: .975rem;"></custom-button>
			</div>

		</el-dialog>
		<choose-case-category ref="chooseCaseCategoryRef" @getItem="e => lawCaseType = e"></choose-case-category>

	</div>
</template>

<script>
	import ChooseCaseCategory from "./ChooseCaseCategory.vue";

	export default {
		components: {
			'choose-case-category': ChooseCaseCategory,
		},
		name: 'SaveTemplate',
		data() {
			return {
				value: '',
				input: '',//案件名称
				lawCaseType:{
					name:'',
				},//选中的案件类别
				searchVal: '',
				dialogVisible: false,
			}
		},
		methods: {
			show() {
				this.dialogVisible = true;
			},
			close() {
				this.input = ""
				this.lawCaseType = {
					name:""
				}
				this.dialogVisible = false;
			},
			clickConfirm(){
				this.$emit('getType', this.input, this.lawCaseType)
				this.close()
			}
		}
	}
</script>
<style lang="scss">
	.save-template {

		.el-dialog {
			border-radius: .25rem;
		}

		.el-dialog__title {
			font-weight: bold;
			color: #000000;
		}

		.el-dialog__headerbtn .el-dialog__close {
			font-size: .45rem;
			color: #909399;
		}

		.save-template-content {
			display: flex;
			flex-direction: column;
			margin-top: 20px;

			>div {
				display: flex;
				align-items: center;
				margin-bottom: .375rem;

				>div {
					&:first-child {
						font-weight: bold;
						font-size: 16px;
						color: #333333;
						margin-right: .25rem;
					}

					&:last-child {
						display: flex;
						align-items: center;
						flex: 1;

						.choose-category {
							display: flex;
							align-items: center;
							justify-content: center;
							white-space: nowrap;
							width: 1.5rem;
							height: 40px;
							background: linear-gradient(90deg, #53B6F4 0%, #1F8CFE 100%);
							border-radius: .1rem;
							font-weight: bold;
							font-size: 16px;
							color: #FFFFFF;
							margin-left: 20px;
							cursor: pointer;
						}
					}
				}
			}
		}


		.dialog-footer {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>